:root {
  --id-tube-rich-grid-items-per-row: 4;
  --id-tube-rich-grid-item-margin: 16px;
  --id-tube-rich-grid-item-min-width: 320px;
  --id-tube-rich-grid-item-max-width: 360px;
}

#contents.id-tube-rich-grid-renderer {
  user-select: none;
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

id-tube-rich-grid-row {
  width: 100%;
  display: flex;
  justify-content: center;
}

id-tube-rich-grid-row #contents.id-tube-rich-grid-row {
  width: 100%;
  max-width: calc(var(--id-tube-rich-grid-items-per-row) * (var(--id-tube-rich-grid-item-max-width) + var(--id-tube-rich-grid-item-margin)));
  margin: 0 var(--id-tube-rich-grid-item-margin);
  display: flex;
}

id-tube-simple-row:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top-width: 0px;
}

id-tube-simple-row:first-of-type #contents.id-tube-simple-row {
  margin: 4px 8px;
}

id-tube-simple-row:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 8px;
}

id-tube-simple-row:last-of-type #contents.id-tube-simple-row {
  margin-bottom: 4px;
}

id-tube-simple-row {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #0008;
  border-top: 1px solid #6B6B6B30;
}

id-tube-simple-row #contents.id-tube-simple-row {
  width: 100%;
  max-width: 100%;
  margin: 2px 8px;
  display: flex;
}

id-tube-simple-item-renderer {
  width: 100%;
}

id-tube-rich-item-renderer {
  position: relative;
  margin-left: calc(var(--id-tube-rich-grid-item-margin)/2);
  margin-right: calc(var(--id-tube-rich-grid-item-margin)/2);
  margin-bottom: 40px;
  width: calc(100%/var(--id-tube-rich-grid-items-per-row) - var(--id-tube-rich-grid-item-margin)-0.01px);
  contain: style layout;
}

id-tube-rich-item-renderer.invisible {
  opacity: 0;
}

id-tube-rich-item-renderer.invisible id-tube-rich-grid-media {
  cursor: default;
}

#content.id-tube-rich-item-renderer {
  height: 100%;
  display: flex;
  justify-content: center;
}

.id-tube-rich-item-renderer .card {
  border-radius: 8px;
  transform: scale(1);
  transition: 200ms;
}

.id-tube-rich-item-renderer .card:hover {
  transform: scale(1.05);
  z-index: 100;
}

id-tube-rich-grid-media {
  width: 100%;
  margin: 0;
  display: block;
  max-width: var(--id-tube-rich-grid-item-max-width);
  cursor: pointer;
}

id-tube-thumbnail img {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
  background-color: black;
}

id-tube-details p {
  margin-left: 8px !important;
}

id-tube-details .title {
  font-size: 22px;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

id-tube-details .sub-title {
  font-size: 16px;
  word-wrap: break-word;
  text-overflow: ellipsis;
}